import actionTypes from "../actionTypes";

export const mensajesGetThreads = (language, token = null) => {
	return {
		type: actionTypes.MENSAJES_THREADS_LOAD_INIT,
		payload: {
			language,
			token,
		},
	};
};

export const mensajesGet = (language, token, anuncio) => {
	return {
		type: actionTypes.MENSAJES_LOAD_INIT,
		payload: {
			language,
			token,
			anuncio,
		},
	};
};

export const mensajeSet = (mensaje) => {
	return {
		type: actionTypes.MENSAJE_SET,
		payload: mensaje,
	};
};

export const mensajesSave = (language, data) => {
	return {
		type: actionTypes.MENSAJES_SAVE_INIT,
		payload: {
			language,
			data,
		},
	};
};
