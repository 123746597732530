import styled, { css } from "styled-components";
import { colors } from "../../../assets/styles/colors";
import { mediaQueryMax } from "../../../assets/styles/size";

export const MessagesWrapper = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	@media ${mediaQueryMax.lg0} {
		border-top: 1px solid ${colors.black01};
	}
`;

export const MessageListBlock = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	position: relative;
	padding: 20px;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	transition: background-color 0.3s ease;
	${(props) => props.selected && `background-color: ${colors.lightgray01}`};
	&:before {
		content: "";
		display: block;
		width: calc(100% - 40px);
		margin: auto;
		height: 1px;
		position: absolute;
		top: 0;
		left: 20px;
		background-color: ${colors.gray};
	}
	span {
		width: 50px;
		height: 50px;
		display: block;
		overflow: hidden;
		border-radius: 50%;
		margin-bottom: 12px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	div {
		${(props) =>
			props.isRead
				? css`
						flex: 0 0 calc(100% - 50px - 20px); // - ancho img - margin
						max-width: calc(100% - 50px - 20px); // - ancho img - margin
				  `
				: css`
						flex: 0 0 calc(100% - 50px - 20px - 10px - 20px); // - ancho img - margin - circulo - margin
						max-width: calc(100% - 50px - 20px - 10px - 20px); // - ancho img - margin - circulo - margin
				  `};
		//margin-left: 20px;
		color: ${colors.darkgray};
		h4 {
			font-weight: 500;
			font-size: 12px;
			line-height: 2;
			margin: 0;
			transition: color 0.3s ease;
			${(props) => (props.selected || !props.isRead) && `color: ${colors.black01}`};
		}
		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 1.7;
			margin: 0;
			//max-width: ${(props) => (props.isRead ? 180 : 160)}px;
      max-width: 100%;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			${(props) => !props.isRead && `color: ${colors.black01}`};
			@media ${mediaQueryMax.lg0} {
				display: block;
        white-space: normal;
        overflow: visible;
			}
			small {
				font-size: 12px;
				overflow: visible;
				white-space: normal;
				color: ${colors.darkgray};
			}
		}
	}
	span {
		&.dot {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			overflow: hidden;
			//margin-left: 20px;
		}
		&.unread {
			background-color: ${colors.red};
		}
	}
`;

export const MessagesList = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	max-width: 42%;
	flex: 0 0 42%;
	@media ${mediaQueryMax.lg0} {
		max-width: 100%;
		flex: 0 0 100%;
	}
	${MessageListBlock} {
		&:first-of-type {
			&:before {
				display: none;
			}
		}
	}
`;

export const ChatBlock = styled.div`
	border-top: 1px solid ${colors.lightgray01};
	border-bottom: 1px solid ${colors.lightgray01};
	margin: 30px 0;
	padding: 30px 0;
	@media ${mediaQueryMax.lg0} {
		margin: 20px 0;
		padding: 20px;
	}
	.chatItem {
		h4 {
			font-weight: 700;
			font-size: 15px;
			line-height: 1.5;
			margin-bottom: 10px;
			span {
				font: inherit;
				font-weight: 300;
			}
		}
		p {
			font-weight: 300;
			font-size: 15px;
			line-height: 1.5;
			color: ${colors.darkgray};
			margin: 0;
		}
		.message {
			margin-bottom: 30px;
		}
		.answer {
			margin-bottom: 30px;
			text-align: right;
		}
	}
`;

export const MessagesChat = styled.div`
	margin-top: 30px;
	max-width: calc(58% - 50px);
	flex: 0 0 calc(58% - 50px);
	@media ${mediaQueryMax.lg0} {
		max-width: 100%;
		flex: 0 0 100%;
	}
	form {
		padding: 0;
		@media ${mediaQueryMax.lg0} {
			padding: 0 20px;
		}
		.inputWrapper {
			position: relative;
			button[type="submit"]:not(.simple) {
				cursor: pointer;
				top: 6px;
				right: 10px;
				position: absolute;
				width: 38px;
				height: 38px;
				display: block;
				padding: 0;
				margin: 0;
				border: 1px solid ${colors.black01};
				background-color: ${colors.black01};
				transition: background-color 0.3s ease;
				> div {
					transform: rotate(90deg);
					display: flex;
					align-items: center;
					justify-content: center;
				}
				svg {
					path {
						transition: fill 0.3s ease;
						fill: ${colors.white};
					}
				}
				&:hover {
					background-color: ${colors.white};
					svg {
						path {
							fill: ${colors.black01};
						}
					}
				}
			}
			button[type="submit"].simple {
				cursor: pointer;
				top: 6px;
				right: 10px;
				position: absolute;
				width: 38px;
				height: 38px;
				display: block;
				padding: 0;
				margin: 0;
				border: 1px solid ${colors.black01};
				background-color: ${colors.black01};
				transition: background-color 0.3s ease;
				> div {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				svg {
					line, polygon {
						transition: fill 0.3s ease;
						fill: ${colors.white};
					}
				}
				&:hover {
					background-color: ${colors.white};
					svg {
						path {
							fill: ${colors.black01};
						}
					}
				}
			}
		}
		input {
			border: 1px solid ${colors.black01};
			padding: 0 58px 0 20px;
			height: 50px;
			&::placeholder {
				color: ${colors.black01};
				opacity: 1;
			}
		}
	}
`;
