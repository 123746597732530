import React from "react";
import { ReactSVG } from "react-svg";
import IconEnvelope from "../../assets/gfx/svgicons/icon-envelope.svg";
import { appUIPopupLoginShow, appUIPopupTypeformShow, appRedirect } from "../../redux/app/actions";
import { connect } from "react-redux";
import { Link } from "gatsby-plugin-intl";

const SidebarButtonsBlock = (props) => {
	const {
		authResolved,
		loggedIn,
		usuarioId,
		anuncio,
		appIsMobile,
		appUIPopupLoginShow,
		appUIPopupTypeformShow,
		appRedirect,
		s,
		showContactar = true,
	} = props;

	const handleContactar = () => {
		appRedirect({ to: `/mensajes/${anuncio.id}` });
	};

	return (
		<div className="sidebar-sticky">
			<div className="sidebar-button">
				<div className="sidebar-info">
					{(anuncio.operacion === 2 || anuncio.operacion === 3) && (
						<p className="price">
							<span>Venta:</span>€{anuncio.precio_venta}
						</p>
					)}
					{(anuncio.operacion === 1 || anuncio.operacion === 3) && (
						<p className="price">
							<span>Alquiler:</span>€{anuncio.precio_alquiler} hora
						</p>
					)}
					<p className="board-type">
						<span>Tabla:</span>
						{anuncio.tabla ? anuncio.tabla.title : anuncio.tabla_otra}
					</p>
					{anuncio.geo_descripcion && (
						<p className="location">
							<span>Ubicación:</span>
							{anuncio.geo_descripcion}
						</p>
					)}
				</div>

				{authResolved && loggedIn && usuarioId !== anuncio.usuario?.id && (
					<>
						{(anuncio.operacion === 1 || anuncio.operacion === 3) && (
							<div className="buttons-wrapper">
								<button
									className="button black"
									onClick={() =>
										appUIPopupTypeformShow("alquilar", {
											price: anuncio.precio_alquiler,
											owner_nombre: anuncio.usuario.nombre,
											owner_apellido: anuncio.usuario.apellido,
											owner_email: anuncio.usuario.email,
											anuncio_id: anuncio.id,
											anuncio_title: anuncio.title,
										})
									}
								>
									Alquilar
								</button>
							</div>
						)}
						{(anuncio.operacion === 2 || anuncio.operacion === 3) && (
							<div className="buttons-wrapper">
								<button
									className="button black"
									onClick={() =>
										appUIPopupTypeformShow("comprar", {
											price: anuncio.precio_venta,
											owner_name: anuncio.usuario.nombre,
										})
									}
								>
									Comprar
								</button>
							</div>
						)}

						{showContactar && (
							<div className="buttons-wrapper">
								<button className="button black" onClick={handleContactar}>
									Contacta
								</button>
							</div>
						)}

						<div className="links-wrapper">
							{authResolved && loggedIn && usuarioId !== anuncio.usuario?.id && appIsMobile && (
								<a
									className="defaultLink"
									onClick={() =>
										appUIPopupTypeformShow("denunciar", {
											anuncio_id: anuncio.id,
											usuario_id: usuarioId,
										})
									}
								>
									Denunciar este anuncio
								</a>
							)}
						</div>
					</>
				)}
				{authResolved && !loggedIn && (
					<button className="button black" onClick={appUIPopupLoginShow}>
						Ingresa para contactar
					</button>
				)}
			</div>

			{authResolved && loggedIn && usuarioId !== anuncio.usuario.id && !appIsMobile && (
				<button
					className="button border-darkgray with-icon"
					onClick={() =>
						appUIPopupTypeformShow("denunciar", {
							anuncio_id: anuncio.id,
							usuario_id: usuarioId,
						})
					}
				>
					<p>Denunciar este anuncio</p>
					<span>
						<ReactSVG src={IconEnvelope} />
					</span>
				</button>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const { resolved: authResolved, loggedIn, usuario } = state.auth;
	const { isMobile: appIsMobile } = state.app;
	return {
		authResolved,
		loggedIn,
		usuario,
		appIsMobile,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupLoginShow,
	appUIPopupTypeformShow,
	appRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarButtonsBlock);
