import React, { useEffect, useState } from "react";
import { ChatBlock, MessageListBlock, MessagesChat, MessagesList, MessagesWrapper } from "./styled";
import { GoBackButton } from "../../../assets/styles/commonStyles";
import GoBackArrow from "../../../assets/gfx/svgicons/icon-goback-arrow.svg";
import SendIcon from "../../../assets/gfx/svgicons/icon-send.svg";
import { ReactSVG } from "react-svg";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";

const MensajeInput = styled.textarea`
	height: 150px;
`;

const MessagesBlock = ({
	threads,
	thread,
	usuario,
	onThreadChange,
	onSendMessage,
	onMessageChange,
	mensaje,
	s,
	anuncio,
}) => {
	const handleSave = (e) => {
		e.preventDefault();
		onSendMessage();
	};

	const handleMensajeChange = (e) => {
		onMessageChange(e.target.value);
	};

	return (
		<MessagesWrapper>
			{threads && (
				<MessagesList>
					{threads.map((t) => (
						<MessageListBlock
							key={t.id}
							selected={anuncio && t.id === anuncio.id}
							isRead={false}
							onClick={() => {
								onThreadChange(t.id);
							}}
						>
							{t.usuario && t.usuario.imagen && t.usuario.imagen.url && (
								<span>
									<img src={t.usuario.imagen.url} alt={t.title} />
								</span>
							)}
							<div>
								<h4>{t.title}</h4>
								{/* <p dangerouslySetInnerHTML={{ __html: t.text }} /> */}
								{/* <p>
									<small>Enviado [FECHA]</small>
								</p> */}
							</div>
							{/* {!message.isRead && <span className="dot unread" />} */}
						</MessageListBlock>
					))}
				</MessagesList>
			)}
			<MessagesChat>
				{anuncio && (
					<GoBackButton to={`/veranuncio/${anuncio.id}`}>
						<span>
							<ReactSVG src={GoBackArrow} />
						</span>
						<p>Volver al anuncio</p>
					</GoBackButton>
				)}

				<ChatBlock>
					{thread &&
						thread.map((m) => {
							return (
								<div className="chatItem">
									{m.usuario_de.id !== usuario.id && (
										<div className="message">
											<h4>
												{m.usuario_de.nombre} <span>{moment.unix(m.created).format("D/M/YYYY")}</span>
											</h4>
											<p>{m.body_plain}</p>
										</div>
									)}
									{m.usuario_de.id === usuario.id && (
										<div className="answer">
											<h4>
												Tú <span>{moment.unix(m.created).format("D/M/YYYY")}</span>
											</h4>
											<p>{m.body_plain}</p>
										</div>
									)}
								</div>
							);
						})}
					{/* <div className="chatItem">
						<div className="message">
							<h4>
								Emilia <span>14 de abril, 09:17</span>
							</h4>
							<p>
								Es para que sepas de antemano que días no podré alquilarte mi tabla, de todos modos no dejes de
								consultarme.
							</p>
						</div>
					</div>
					<div className="chatItem">
						<div className="message">
							<h4>
								Emilia <span>14 de abril, 09:17</span>
							</h4>
							<p>
								Es para que sepas de antemano que días no podré alquilarte mi tabla, de todos modos no dejes de
								consultarme.
							</p>
						</div>
						<div className="answer">
							<h4>
								Tú <span>14 de abril, 09:17</span>
							</h4>
							<p>
								Es para que sepas de antemano que días no podré alquilarte mi tabla, de todos modos no dejes de
								consultarme.
							</p>
						</div>
					</div> */}
				</ChatBlock>

				{anuncio && (
					<form onSubmit={handleSave}>
						<div className="inputWrapper">
							{/* <input type="text" placeholder="Escribir mensaje" /> */}
							<MensajeInput placeholder="Escribe tu mensaje..." value={mensaje} onChange={handleMensajeChange} />
							<button type="submit" className="simple">
								<ReactSVG src={SendIcon} />
							</button>
							{/* <button type="submit" className="simple" style={{ color: "white" }}>HEY</button> */}
						</div>
					</form>
				)}
			</MessagesChat>
		</MessagesWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
		anuncio: state.anuncios.anuncio,
	};
};

export default connect(mapStateToProps)(MessagesBlock);
