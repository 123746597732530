import React, { useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import { connect } from "react-redux";
import SEO from "../components/seo";
import { navigate, useIntl } from "gatsby-plugin-intl";
import MessagesBlock from "../components/common/MessagesBlock";
import MapCustom from "../components/common/MapCustom";
import SidebarButtonsBlock from "../components/SidebarButtonsBlock";
import { mensajesGetThreads, mensajesGet, mensajesSave, mensajeSet } from "../redux/mensajes/actions";
import { appRedirect } from "../redux/app/actions";
import { anuncioGet } from "../redux/anuncios/actions";

const MensajesPage = ({
	appIsMobile,
	loggedIn,
	authResolved,
	usuario,
	threads,
	thread,
	mensaje,
	anuncio,
	mensajesGetThreads,
	mensajesGet,
	mensajesSave,
	mensajeSet,
	location,
	appRedirect,
	anuncioGet,
	s,
}) => {
	const intl = useIntl();

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	useEffect(() => {
		if (loggedIn && authResolved) {
			const pathParts = location.pathname.split("/");
			const id = parseInt(pathParts[pathParts.length - 1]);
			mensajesGetThreads(intl.locale, usuario.token);
			if (id) {
				mensajesGet(intl.locale, usuario.token, id);
				anuncioGet(intl.locale, id);
			}
		} else if (authResolved && !loggedIn) {
			appRedirect({ to: "/" });
		}
	}, [authResolved]);

	const handleThreadChange = (id) => {
		appRedirect({ to: `/mensajes/${id}` });
		mensajesGet(intl.locale, usuario.token, id);
		anuncioGet(intl.locale, id);
	};

	const handleSendMessage = () => {
		mensajesSave(intl.locale, {
			token: usuario.token,
			anuncio: anuncio ? anuncio.id : null,
			text: mensaje,
		});
	};

	const handleMessageChange = (e) => {
		mensajeSet(e);
	};

	return (
		<>
			<BodyClassName className="two-col-page">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Mensajes" />
					<main className="page">
						<article className="two-col-wrapper">
							{/* MAIN */}
							<div className="two-col-main">
								<MessagesBlock
									threads={threads}
									thread={thread}
									usuario={usuario}
									onThreadChange={handleThreadChange}
									onSendMessage={handleSendMessage}
									onMessageChange={handleMessageChange}
									mensaje={mensaje}
								/>
							</div>
							{/* MAIN */}

							{/* SIDEBAR */}
							{anuncio && (
								<div className="two-col-sidebar">
									<article className="sidebar">
										{/* LENDER PROFILE FOR DESKTOP */}
										<div className="sidebar-profile show-lg">
											<div className="sidebar-profile-img">
												{anuncio.usuario && anuncio.usuario.imagen && (
													<img
														className="profile-img"
														src={anuncio.usuario.imagen.url}
														alt={`${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
													/>
												)}
												{anuncio.usuario && !anuncio.usuario.imagen && (
													<img
														className="profile-img"
														src="/img/user.svg"
														alt={`${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
													/>
												)}
												<h2 className="name white-text saint-font">
													{anuncio.usuario && `${anuncio.usuario.nombre} ${anuncio.usuario.apellido}`}
												</h2>
											</div>
											<p className="likes white-text">{anuncio.usuario && anuncio.usuario.descripcion}</p>
										</div>
										{/* /LENDER PROFILE FOR DESKTOP */}

										{anuncio?.imagenes && (
											<div className="sidebar-image">
												<img className="image" src={anuncio?.imagenes[0]?.url} alt={anuncio.titulo} />
											</div>
										)}

										{anuncio && anuncio.lat && anuncio.long && !appIsMobile && (
											<div className="sidebar-map">
												<MapCustom lat={anuncio.lat} long={anuncio.long} />
											</div>
										)}

										<SidebarButtonsBlock anuncio={anuncio} showContactar={false} />
									</article>
								</div>
							)}
							{/* SIDEBAR */}
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	const { loggedIn, resolved: authResolved, usuario } = state.auth;
	const { threads, thread, mensaje } = state.mensajes;
	const { isMobile: appIsMobile } = state.app;
	const { anuncio } = state.anuncios;
	return {
		loggedIn,
		authResolved,
		usuario,
		threads,
		thread,
		mensaje,
		appIsMobile,
		anuncio,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	mensajesGetThreads,
	mensajesGet,
	mensajesSave,
	mensajeSet,
	appRedirect,
	anuncioGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(MensajesPage);
